import { Wrapper as GoogleMapWrapper } from "@googlemaps/react-wrapper";
import { Colors } from "components/colors";
import { APIError } from "libs/api/client";
import Head from "next/head";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { createGlobalStyle } from "styled-components";
import { SWRConfig } from "swr";

type AppProviderProps = {
  children: ReactNode;
  configureSWRForTest?: boolean;
};

export const AppProvider = ({
  children,
  configureSWRForTest = false,
}: AppProviderProps) => {
  const router = useRouter();
  return (
    <>
      <Head>
        <title>CREW Express</title>
        <link
          rel="stylesheet"
          href="https://unpkg.com/modern-css-reset/dist/reset.min.css"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Material+Icons+Two+Tone"
        ></link>
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <meta name="viewport" content="" />
      </Head>
      <GlobalStyle />
      <SWRConfig
        value={
          configureSWRForTest
            ? {
                dedupingInterval: 0,
                provider: () => new Map(),
              }
            : {
                onError: (err) => {
                  if (err instanceof APIError) {
                    switch (err.status) {
                      case 401:
                        router.push("/login");
                        break;
                      case 403:
                        router.push("/account_locked");
                        break;
                    }
                  }
                },
              }
        }
      >
        {/* GoogleMapを利用するページが複数存在するが、GoogleMapWrapperはリロードを挟まずに複数回実行するとエラーになるため、アプリ全体で共通化する*/}
        <GoogleMapWrapper
          apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY || ""}
          libraries={["places"]}
        >
          {children}
        </GoogleMapWrapper>
      </SWRConfig>
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${Colors.primaryBase};
    font-family: Noto Sans CJK JP;
    font-style: normal;
    font-weight: normal;
  }

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
`;
