import * as Sentry from "@sentry/nextjs";
import { AppProvider } from "admin/providers/app";
import { getApps, initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { NextPage } from "next";
import type { AppProps } from "next/app";
import { ReactElement, ReactNode, useEffect } from "react";

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const pageContent = getLayout(<Component {...pageProps} />);

  if (getApps().length === 0) {
    initializeApp({
      apiKey: "AIzaSyCaefgtpThXSdbjmQH59LuxIRLa4MB8kgA",
      authDomain: "crew-express-5d634.firebaseapp.com",
      databaseURL: "https://crew-express-5d634.firebaseio.com",
      projectId: "crew-express-5d634",
      storageBucket: "crew-express-5d634.appspot.com",
      messagingSenderId: "349977201416",
      appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
      measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
    });
  }

  useEffect(() => {
    const f = async () => {
      // ブラウザがServiceWorkerに対応していない場合は何もしない
      if (!("serviceWorker" in navigator)) {
        return;
      }
      const registration = await navigator.serviceWorker.register(
        `/firebase-messaging-sw.js?appId=${process.env.NEXT_PUBLIC_FIREBASE_APP_ID}&measurementId=${process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID}`
      );
      const messaging = getMessaging();
      await getToken(messaging, {
        serviceWorkerRegistration: registration,
      }).catch((error) => {
        // パーミッションが許可されていない場合は何もしない
        if (error.code !== "messaging/permission-blocked") {
          Sentry.captureException(error);
        }
      });
    };
    f();
  }, []);

  return <AppProvider>{pageContent}</AppProvider>;
}

export default MyApp;
