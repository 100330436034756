var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"eyJyZWYiOiIxMzE4MWJmN2ExZGNjMzFiNWMyNWQxOTBlNDMzZTAyYzBmYmUyNWNhIiwiZW52IjoiZDkyOGE5YmE4NzQwYTg5MTIxNjc3MDZmZmUxNDc3ODZjZGIxZWQ2NyJ9"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NEXT_PUBLIC_APP_ENV) {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      "https://1f6748be98b24c7898c43d7191a0333d@o286039.ingest.sentry.io/4505026856878080",
    environment: process.env.NEXT_PUBLIC_APP_ENV,
    tracesSampleRate: 1.0,
    ignoreErrors: ["This error can be safely ignore"],
  });
}
