import { stringify } from "qs";

export type InputArguments = {
  signal?: AbortSignal;
  formData?: FormData;
  cookie?: string;
};

export class APIError extends Error {
  public abort?: boolean;
  public status?: number;
  public type?: string;
  public details?: string[];

  constructor(args: {
    response?: Response;
    abort?: boolean;
    type?: string;
    details?: string[];
  }) {
    super(args.type || "");

    this.abort = args.abort;
    this.status = args.response?.status;
    this.type = args.type;
    this.details = args.details;

    Object.defineProperty(this, "name", {
      configurable: true,
      enumerable: false,
      value: "APIError",
      writable: true,
    });

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError);
    }
  }
}

export type TypedResponse<JSONType> = Omit<Response, "json"> & {
  json(): Promise<JSONType>;
};

export async function request({
  method,
  url,
  params: { signal, query = {}, body, formData, cookie },
}: {
  method: string;
  url: string;
  params: InputArguments & { query?: any; body?: any };
}): Promise<any> {
  const requestPrefix =
    typeof window !== "undefined" ? "/api" : process.env.API_ENDPOINT;
  const fullUrl = `${requestPrefix}${url}${
    Object.keys(query).length ? `?${stringify(query)}` : ""
  }`;

  const controller =
    typeof window !== "undefined"
      ? new AbortController()
      : new (class {
          public signal = null;
          public abort() {}
        })();
  const headers = {
    Accept: "application/json",
    ...(!formData ? { "Content-Type": "application/json" } : {}),
    ...(cookie ? { Cookie: cookie } : {}),
  };
  if (formData) {
    formData.append("jsonData", JSON.stringify(body));
  }
  const init = {
    method,
    headers,
    mode: "cors",
    ...(formData
      ? { body: formData }
      : body
      ? { body: JSON.stringify(body) }
      : {}),
    signal: signal || controller.signal,
    credentials: "include",
  };
  const response = await fetch(fullUrl, init as any).catch((e) => {
    throw new APIError({ abort: e.name === "AbortError" });
  });
  if (response.ok) {
    return response;
  }
  const json = await response.json().catch(() => {
    throw new APIError({ response });
  });
  const type = json.error?.type;
  const details = json.error?.details;

  if (typeof type === "string") {
    throw new APIError({ response, type, details });
  } else {
    throw new APIError({ response });
  }
}
